import styled from 'styled-components';
import React, { ChangeEventHandler, useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from './Button';
import { colors } from '../utils/siteVars';
import { parseDate } from '../utils/parseDate';
import TechnicianFormInput from './TechnicianFormInput';
import { workPlanFormDefault } from '../utils/defaults';
import {
  workPlanEquipmentItems,
  workPlanRiskOverviewItems,
  workPlanWorkMomentDescriptions,
} from '../utils/data';
import Switch from './Switch';
import Plus from './Plus';
import DateInput from './DateInput';
import { CustomerBM, UserFM, WorkPlanBM } from '@bm-js/h2o-shared';

const StyledWorkPlanForm = styled.form`
  .fields-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
  .label {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
  }
  .pointer {
    cursor: pointer;
  }
  .flex {
    display: flex;
  }
  .jce {
    justify-content: flex-end;
  }
  .workplan-rows {
    margin-bottom: 3rem;
  }
  .workplan-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.mediumGrey};
    margin-bottom: 0.8rem;
    p {
      margin: 0;
      &.item-text {
        margin-bottom: 0.8rem;
      }
    }
  }
  .bold {
    font-weight: 700;
  }
  .workmoment-desc-container {
    margin-bottom: 4rem;
    .workmoment-desc-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      input {
        width: 400px;
        margin: 0;
        margin-bottom: 0.3rem;
        padding: 0.3rem;
      }
      .cross {
        cursor: pointer;
      }
      p {
        margin: 0;
        margin-bottom: 0.3rem;
      }
    }
  }
  .selected-reservoir-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
      margin-bottom: 0.3rem;
      font-size: 14px;
      font-weight: 500;
    }
  }
  @media (max-width: 1000px) {
    .fields-grid {
      grid-template-columns: 1fr;
    }
  }
`;

type Props = {
  data: WorkPlanBM | null;
  customer: CustomerBM | null;
  users: UserFM[];
  handleSubmit: (
    formData: any,
    customer: CustomerBM | null
  ) => Promise<void> | void;
};

const WorkPlanForm = ({ data, customer, users, handleSubmit }: Props) => {
  const [formData, setFormData] = useState<any>(workPlanFormDefault);

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const parsePayload = () => {
    const getReservoir = (reservoirId: string) => {
      const fullReservoir = customer?.reservoirs.find(
        (res) => res._id === reservoirId
      );
      if (fullReservoir)
        return {
          id: fullReservoir._id,
          name: fullReservoir.basicInformation.name,
        };
      else return {};
    };
    const getOrderPerson = (id: string) => {
      const person = customer?.contactPersons.find((pers) => pers._id === id);
      return person || { _id: id };
    };
    const parsed = {
      ...formData,
      reservoirs: (formData.reservoirs || []).map(getReservoir),
      reservoir: getReservoir(formData.reservoir),
      orderPerson: getOrderPerson(formData.orderPerson),
    };
    return parsed;
  };

  const dateValue = (d: string) => {
    return d ? parseDate(d) : '';
  };

  const addWorkMomentDescription: ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const updated = [...(formData.workMomentDescriptions || [])];
    const item = workPlanWorkMomentDescriptions.find(
      (desc) => desc.key === e.target.value
    );
    updated.push({ ...item });
    setFormData({
      ...formData,
      workMomentDescriptions: updated,
    });
  };

  const removeWorkMomentDescription = (key: string) => {
    const updated = [...formData.workMomentDescriptions];
    const spliceIndex = updated.findIndex((desc) => desc.key === key);
    if (spliceIndex === -1) return;
    updated.splice(spliceIndex, 1);
    setFormData({
      ...formData,
      workMomentDescriptions: updated,
    });
  };

  const handleWorkMomentDescriptionOtherChange: ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const updated = [...formData.workMomentDescriptions];
    const index = updated.findIndex((desc) => desc.key === 'other');
    if (index === -1) return;
    updated[index].text = e.target.value;
    setFormData({
      ...formData,
      workMomentDescriptions: updated,
    });
  };

  const workMomentDescriptionOtherValue = useMemo(() => {
    return (
      (formData.workMomentDescriptions || []).find(
        (desc: any) => desc.key === 'other'
      )?.text || ''
    );
  }, [formData.workMomentDescriptions]);

  const populateEmptyKeys = (defaults: any, existing: any) => {
    const updated: any = [];
    defaults.forEach((defaultVal: any) => {
      const existingVal = existing.find((ex: any) => ex.key === defaultVal.key);
      if (existingVal) updated.push(existingVal);
      else updated.push(defaultVal);
    });
    return updated;
  };

  const addReservoir = (reservoirId: string) => {
    const updated = [...(formData.reservoirs || [])];
    updated.push(reservoirId);
    setFormData({
      ...formData,
      reservoirs: updated,
    });
  };

  const removeReservoir = (reservoirId: string) => {
    const updated = formData.reservoirs.filter(
      (res: string) => res !== reservoirId
    );
    setFormData({
      ...formData,
      reservoir: formData.reservoir === reservoirId ? '' : formData.reservoir,
      reservoirs: updated,
    });
  };

  const getReservoirsFromLegacyReservoir = (
    formData: WorkPlanBM | null
  ): string[] => {
    if (!formData) return [];
    if (!formData.reservoir) return formData.reservoirs.map((res) => res.id);
    const alreadyInArray = formData.reservoirs.find(
      (res) => res.id === formData.reservoir?.id
    );
    if (alreadyInArray) return formData.reservoirs.map((res) => res.id);
    return [...formData.reservoirs, { id: formData.reservoir.id }].map(
      (res) => res.id
    );
  };

  const getReservoirNameById = (id: string) => {
    const reservoir = customer?.reservoirs.find((res) => res._id === id);
    return reservoir?.basicInformation.name || '';
  };

  useEffect(() => {
    if (data) {
      const reservoirs = getReservoirsFromLegacyReservoir(data);
      setFormData({
        ...data,
        reservoirs,
        reservoir: data.reservoir?.id || '',
        orderPerson: data.orderPerson?._id || '',
        riskOverview: populateEmptyKeys(
          workPlanRiskOverviewItems,
          data.riskOverview
        ),
        equipment: populateEmptyKeys(workPlanEquipmentItems, data.equipment),
      });
    } else {
      setFormData({
        ...workPlanFormDefault,
        riskOverview: workPlanRiskOverviewItems,
        equipment: workPlanEquipmentItems,
      });
    }
    //eslint-disable-next-line
  }, [data]);

  return (
    <StyledWorkPlanForm
      onSubmit={(e) => {
        e.preventDefault();
        const parsedFormData = parsePayload();
        handleSubmit(parsedFormData, customer);
      }}
    >
      <div className="fields-grid">
        <div>
          <p className="label">Arbetsmiljöplan upprättades</p>
          <DateInput
            required
            name="creationDate"
            value={dateValue(formData.creationDate)}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="label">Reservoarer</p>
          {/* eslint-disable-next-line */}
          <select
            name="reservoir"
            onChange={(e) => addReservoir(e.target.value)}
            value={''}
          >
            <option disabled value="">
              Lägg till reservoar
            </option>
            {customer?.reservoirs.map((reservoir) => (
              <option
                key={reservoir._id}
                value={reservoir._id}
                disabled={formData.reservoirs?.includes(reservoir._id)}
              >
                {reservoir.basicInformation.name}
              </option>
            ))}
          </select>
          {(formData.reservoirs || []).map((reservoirId: string) => (
            <div
              key={`selected_${reservoirId}`}
              className="selected-reservoir-row"
            >
              <p>{getReservoirNameById(reservoirId)}</p>
              <div
                className="pointer"
                onClick={() => removeReservoir(reservoirId)}
              >
                <Plus cross color={colors.red} />
              </div>
            </div>
          ))}
        </div>
        <div>
          <p className="label">Arbetsmiljöplan startdatum</p>
          <DateInput
            required
            name="startDate"
            value={dateValue(formData.startDate)}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="label">Arbetsmiljöplan slutdatum</p>
          <DateInput
            required
            name="endDate"
            value={dateValue(formData.endDate)}
            onChange={handleChange}
          />
        </div>

        <div>
          <p className="label">Beställare - kontaktperson</p>
          {/* eslint-disable-next-line */}
          <select
            value={formData.orderPerson || ''}
            name="orderPerson"
            required
            onChange={handleChange}
          >
            <option value="">Välj kontaktperson</option>
            {customer?.contactPersons.map((person) => (
              <option key={person._id} value={person._id}>
                {person.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p className="label">Utförare</p>
          <TechnicianFormInput
            name="technicians"
            label={'servicetekniker'}
            users={users}
            value={formData.technicians}
            onChange={handleChange}
          />
        </div>
      </div>

      <hr />
      <p className="bold">Arbeten med särskild risk som kommer att förekomma</p>
      <div className="workplan-rows">
        {formData.riskOverview.map((item: any, i: number) => (
          <div className="workplan-row" key={`workplan_row_${i}`}>
            <p className="item-text">{item.text}</p>
            <Switch
              placeholder="Nej"
              name={`riskOverview_${item.key}`}
              secondPlaceholder="Ja"
              checked={item.value}
              onChange={(e) => {
                const updated = [...formData.riskOverview];
                updated[i].value = e.target.checked;
                setFormData({ ...formData, riskOverview: updated });
              }}
            />
          </div>
        ))}
      </div>
      <p className="bold">Specifik utrustning för detta jobb</p>
      <div className="workplan-rows">
        {formData.equipment.map((item: any, i: number) => (
          <div className="workplan-row" key={`workplan_row_${i}`}>
            <p className="item-text">{item.text}</p>
            <Switch
              placeholder="Nej"
              name={`equipment_${item.key}`}
              secondPlaceholder="Ja"
              checked={item.value}
              onChange={(e) => {
                const updated = [...formData.equipment];
                updated[i].value = e.target.checked;
                setFormData({ ...formData, equipment: updated });
              }}
            />
          </div>
        ))}
      </div>

      <p className="bold">Beskrivning arbetsmoment</p>
      <select value="" onChange={addWorkMomentDescription}>
        <option disabled value="">
          Välj moment
        </option>
        {workPlanWorkMomentDescriptions.map((desc) => (
          <option
            key={desc.key}
            value={desc.key}
            disabled={
              !!formData.workMomentDescriptions.find(
                (d: any) => desc.key === d.key
              )
            }
          >
            {desc.key === 'other' ? 'Övrigt' : desc.text}
          </option>
        ))}
      </select>

      <div className="workmoment-desc-container">
        {(formData.workMomentDescriptions || []).map((desc: any, i: number) => (
          <div
            key={`${desc.key}_${i}_display`}
            className="workmoment-desc-item"
          >
            {desc.key === 'other' ? (
              <div>
                -{' '}
                <input
                  placeholder="Övrigt"
                  value={workMomentDescriptionOtherValue}
                  onChange={handleWorkMomentDescriptionOtherChange}
                />
              </div>
            ) : (
              <p>- {desc.text}</p>
            )}
            <div
              onClick={() => removeWorkMomentDescription(desc.key)}
              className="cross"
            >
              <Plus cross color={colors.red} />
            </div>
          </div>
        ))}
      </div>

      <Button type="submit" small>
        Spara
      </Button>
    </StyledWorkPlanForm>
  );
};

export default WorkPlanForm;
