import { OfferBM } from '@bm-js/h2o-shared';
import React, { useMemo } from 'react';
import { serviceOptions } from '../utils/data';
import Plus from './Plus';
import { parseSEK } from '../utils/parseSEK';
import { colors } from '../utils/siteVars';
import Button from './Button';

type Props<T extends OfferBM> = {
  formData: T;
  setFormData: (v: T) => void;
};

const sortedServices = [...serviceOptions].sort((a, b) => {
  if (a.sortBottom) return 1;
  return a.name.localeCompare(b.name, 'sv');
});

const OfferFormTable = <T extends OfferBM>({
  formData,
  setFormData,
}: Props<T>) => {
  const totalSum = useMemo(() => {
    const rowSums = formData.table.map((row) => {
      return row.priceExVat * row.quantity;
    });
    const totalSum = rowSums.reduce<number>((acc, curr) => acc + curr, 0);
    return totalSum;
  }, [formData.table]);

  return (
    <>
      {formData.table.map((row: any, i: number) => (
        <div className="service-row user-row" key={`service_row_${i}`}>
          <div>
            <datalist id="offer-form-datalist">
              {sortedServices.map((service) => (
                <option
                  key={`offer_service_option_${i}_${service.name}`}
                  disabled={service.disabled}
                >
                  {service.name}
                </option>
              ))}
            </datalist>
            <div className="service-input-wrapper">
              <input
                list="offer-form-datalist"
                required
                value={row.service}
                onChange={(e) => {
                  const updated = [...formData.table];
                  updated[i].service = e.target.value;
                  const service = serviceOptions.find(
                    (s) => s.name === e.target.value
                  );
                  if (service) {
                    updated[i].id = service.id as string;
                    updated[i].priceExVat =
                      serviceOptions.find((s) => s.name === e.target.value)
                        ?.priceExVat || 0;
                  }
                  setFormData({
                    ...formData,
                    table: updated,
                  });
                }}
              />
              {/* eslint-disable-next-line */}
              <div
                className="cross"
                onClick={() => {
                  const updated = [...formData.table];
                  updated[i].service = '';
                  updated[i].id = '';
                  updated[i].priceExVat = 0;
                  setFormData({ ...formData, table: updated });
                }}
              >
                <Plus color={'black'} cross />
              </div>
            </div>
          </div>
          <input
            placeholder="Antal"
            type="number"
            value={row.quantity}
            required
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].quantity = e.target.value as unknown as number;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          />

          {/* eslint-disable-next-line */}
          <select
            required
            value={row.unit}
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].service = e.target.value;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          >
            <option value="" disabled>
              Välj enhet
            </option>
            <option>st</option>
          </select>
          <input
            placeholder="Á-pris"
            type="number"
            value={row.priceExVat}
            required
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].priceExVat = e.target.value as unknown as number;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          />
          <p className="row-sum">{parseSEK(row.priceExVat * row.quantity)}</p>
          {/* eslint-disable-next-line */}
          <div
            className="pointer asc mbottom1"
            onClick={() => {
              const updated = [...formData.table];
              updated.splice(i, 1);
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          >
            <Plus cross color={colors.red} />
          </div>
        </div>
      ))}
      <Button
        type="button"
        small
        onClick={() => {
          const updated = [...formData.table];
          // @ts-ignore
          updated.push({
            service: '',
            quantity: 1,
            unit: 'st',
            priceExVat: 0,
          });
          setFormData({
            ...formData,
            table: updated,
          });
        }}
      >
        Lägg till rad
      </Button>
      <div className="flex jcfe mright2 mbottom05">
        <p className="m0">
          Summa ex moms: <span>{parseSEK(totalSum)}</span>
        </p>
      </div>
      <div className="flex jcfe mright2">
        <p className="m0">
          Summa ink moms: <span>{parseSEK(totalSum * 1.25)}</span>
        </p>
      </div>
    </>
  );
};

export default OfferFormTable;
