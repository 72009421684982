import React from 'react';
import Plus from './Plus';
import { colors } from '../utils/siteVars';
import styled from 'styled-components';

const StylesTechnicianFormInput = styled.div`
  margin-bottom: 1.5rem;
  .empty-state {
    font-size: 14px;
    font-weight: 500;
  }
  .selected-technicians {
    margin-bottom: 1rem;

    .selected-technician {
      margin-bottom: 0.3rem;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      span {
        margin-right: 1rem;
      }
    }
  }
`;

type Technician = {
  name?: string;
  firstName?: string;
  lastName?: string;
  _id?: string;
};

type Props = {
  users: Technician[];
  onChange: (e: any) => void;
  name: string;
  label: string;
  value: Technician[];
};

const TechnicianFormInput = ({
  users,
  value = [],
  onChange,
  name,
  label,
}: Props) => {
  const userName = (user: Technician) =>
    user.name || `${user.firstName} ${user.lastName}`;
  return (
    <StylesTechnicianFormInput>
      {/* eslint-disable-next-line */}
      <select
        value=""
        onChange={(e) => {
          const updated = [...value];
          const relevant = users.filter((u) => u._id === e.target.value)[0];
          updated.push(relevant);
          const event = {
            target: {
              name,
              value: updated,
            },
          };
          onChange(event);
        }}
      >
        <option disabled value="">
          Välj {label}
        </option>
        {users.map((user, i) => (
          <option
            key={`${user._id}_overlayform_${i}_${label}_opt`}
            value={user._id}
          >
            {userName(user)}
          </option>
        ))}
      </select>
      <div className="selected-technicians">
        {value.length ? (
          value.map((user, i) => (
            <div
              key={`${user._id}__selectedTechnician_${i}_${label}`}
              className="selected-technician"
            >
              <span>{userName(user)}</span>
              {/* eslint-disable-next-line */}
              <div
                className="pointer"
                onClick={() => {
                  const updated = [...value];
                  updated.splice(i, 1);
                  const event = {
                    target: {
                      name,
                      value: updated,
                    },
                  };
                  onChange(event);
                }}
              >
                <Plus cross color={colors.red} />
              </div>
            </div>
          ))
        ) : (
          <p className="empty-state">Ingen {label} vald</p>
        )}
      </div>
    </StylesTechnicianFormInput>
  );
};

export default TechnicianFormInput;
